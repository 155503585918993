<template>
  <Component
    :is="tag"
    class="collapse"
    :class="{ 'collapse--expanded': collapseActive }"
  >
    <slot />
  </Component>
</template>

<script lang="ts" setup>
const props = withDefaults(
  defineProps<{
    id: string
    tag?: keyof HTMLElementTagNameMap
    initialActive?: boolean
  }>(),
  {
    tag: 'div'
  }
)

const isActive = useRequiredInject<(id: string) => boolean>('is-active')
const collapseActive = computed(() => isActive(props.id))

const handleChangeFactory = useRequiredInject<
  (id: string) => (active: boolean) => void
>('handle-change-factory')
const handleChange = handleChangeFactory(props.id)

handleChange(props.initialActive)

provide('collapse-id', props.id)
provide('section-id', `${props.id}_section`)
provide('collapse-active', collapseActive)
provide('handle-change', handleChange)
</script>
