<template>
  <component :is="tag" v-if="tag">
    <slot />
  </component>
  <slot v-else />
</template>

<script lang="ts" setup>
const props = withDefaults(
  defineProps<{
    oneAlwaysActive?: boolean
    onlyOneActive?: boolean
    tag?: keyof HTMLElementTagNameMap
  }>(),
  { onlyOneActive: true, tag: undefined }
)

const activeItems = defineModel<string[]>({ default: [] })
const isOnlyOneActive = computed(() => (activeItems.value ?? []).length <= 1)

type ArrayFn = <T>(arr: T[], value: T) => T[]
const MODE_ADD_DICT: Record<'one' | 'multiple', ArrayFn> = {
  one: (_, value) => [value],
  multiple: (arr, value) => [...arr, value]
}
const omit: ArrayFn = (arr, value) => arr.filter(v => v !== value)

const handleChangeFactory = (id: string) => (active: boolean) => {
  // Check if the user is trying to close an always active collapse
  if (props.oneAlwaysActive && !active && isOnlyOneActive.value) return

  const MODE = props.onlyOneActive ? 'one' : 'multiple'
  const modeAdd = MODE_ADD_DICT[MODE]

  const arrayFn = active ? modeAdd : omit

  activeItems.value = arrayFn(activeItems.value ?? [], id)
}

const isActive = (id: string) => (activeItems.value ?? []).includes(id)

provide('handle-change-factory', handleChangeFactory)
provide('is-active', isActive)
</script>
