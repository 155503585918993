<template>
  <div
    :id="sectionId"
    role="region"
    :aria-labelledby="id"
    class="collapse-content"
  >
    <div class="collapse-content__inner">
      <slot :is-active="isCollapseActive" />
    </div>
  </div>
</template>

<script setup lang="ts">
const id = useRequiredInject<string>('collapse-id')
const sectionId = useRequiredInject<string>('section-id')
const isCollapseActive = useRequiredInject<boolean>('collapse-active')
</script>

<style scoped lang="scss">
.collapse-content {
  display: grid;
  grid-template-rows: 0fr;
  opacity: 0;
  transition:
    grid-template-rows $transition,
    opacity 0.2s 0.2s;

  &__inner {
    overflow: hidden;
  }

  .collapse--expanded & {
    grid-template-rows: 1fr;
    opacity: 1;
  }
}
</style>
