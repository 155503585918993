<template>
  <div
    :id="id"
    role="button"
    :aria-expanded="isCollapseActive"
    :aria-controls="sectionId"
    :aria-disabled="disabled"
    :tabindex="disabled ? -1 : 0"
    @click.prevent="toggleExpanded"
    @keydown.enter.space.prevent="toggleExpanded"
  >
    <slot />
  </div>
</template>

<script lang="ts" setup>
const props = defineProps<{ disabled?: boolean }>()

const id = useRequiredInject<string>('collapse-id')
const sectionId = useRequiredInject<string>('section-id')
const isCollapseActive = useRequiredInject<Ref<boolean>>('collapse-active')

const handleChange =
  useRequiredInject<(active: boolean) => void>('handle-change')
const toggleExpanded = () =>
  !props.disabled && handleChange(!isCollapseActive.value)
</script>
